<template>
  <div id="page-users-list">
    <KCrudLayout :search.sync="searchQuery">
      <template #header>
        {{ $tc('location.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('location.title', 1) }) }}
        </v-btn>
        <LocationForm
          v-model="createDialog"
          :employer="employer"
          :request="createRequest"
          :title="$tc('location.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="location.fields"
          resource="location"
          @click:edit="openUpdate"
          @click:row="$router.push({ name: 'employer.location.show', params: { employerId: employer.id, locationId: $event.id } })"
        />
      </template>
    </KCrudLayout>
    <LocationForm
      v-model="updateDialog"
      :employer="employer"
      :request="updateRequest"
      :title="$tc('location.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Location from '@/application/models/Location.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/location/api/location.js';
import LocationForm from '@/modules/location/components/LocationForm.vue';

export default {
  name: 'LocationIndex',
  components: {
    LocationForm,
    KCrudTable,
    KCrudLayout,
  },
  props: {
    employer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Location(),
      createFormValues: new Location(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'phone',
          language: 'contactDetail.phone',
        },
        {
          value: 'email',
          language: 'contactDetail.email',
        },
        {
          value: 'city',
          language: 'address.city',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.location.index' },
        text: this.$tc('location.title', 2),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new Location();
      this.createDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.$route.params.employerId, this.createFormValues);
    },
    indexRequest() {
      return index(this.$route.params.employerId, ...arguments);
    },
    async openUpdate(item) {
      this.updateFormValues = new Location();
      const locationShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(locationShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
